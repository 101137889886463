import {
  Box,
  Flex,
  HStack,
  Text,
  Skeleton,
  Grid,
} from '@chakra-ui/react';
import React from 'react';

import type { Transaction } from 'types/api/transaction';

import config from 'configs/app';
import getValueWithUnit from 'lib/getValueWithUnit';
import useTimeAgoIncrement from 'lib/hooks/useTimeAgoIncrement';
import { currencyUnits } from 'lib/units';
import AddressFromTo from 'ui/shared/address/AddressFromTo';
import BlockEntity from 'ui/shared/entities/block/BlockEntity';
import TxEntity from 'ui/shared/entities/tx/TxEntity';
import TxFeeStability from 'ui/shared/tx/TxFeeStability';
import TxType from 'ui/txs/TxType';

type Props = {
  tx: Transaction;
  isLoading?: boolean;
}

const LatestTxsItem = ({ tx, isLoading }: Props) => {
  const dataTo = tx.to ? tx.to : tx.created_contract;
  const timeAgo = useTimeAgoIncrement(tx.timestamp || '0', true);

  return (
    <Grid
      gridTemplateColumns={{
        lg: '1fr',
        xl: '1fr',
      }}
      gridGap={3}
      width="100%"
      borderBottom="1px solid"
      borderColor="divider"
      _last={{ borderBottom: '0px solid', borderColor: 'divider' }}
      display={{ base: 'grid', lg: 'grid' }}
      pb={6}
    >
      <Flex overflow="hidden" w="100%">
        <Box w="100%">
          <HStack justifyContent="space-between" mb={3}>
            <TxType types={tx.tx_types} isLoading={isLoading} />
            {tx.timestamp && (
              <Skeleton
                isLoaded={!isLoading}
                color="text_secondary"
                fontWeight="400"
                fontSize="sm"
                flexShrink={0}
                ml={2}
              >
                <span>{timeAgo}</span>
              </Skeleton>
            )}
          </HStack>

          <BlockEntity
            isLoading={isLoading}
            number={tx.block}
            tailLength={2}
            fontSize="sm"
            lineHeight={7}
            fontWeight={500}
            mr="auto"
          />

          <Flex
            alignItems="center"
            mt="6px"
          >
            <TxEntity
              isLoading={isLoading}
              hash={tx.hash}
              fontWeight="700"
              hasIcon={false}
            />
          </Flex>
        </Box>
      </Flex>

      <Flex flexDir="row" justifyContent="space-between">
        <AddressFromTo
          from={tx.from}
          to={dataTo}
          isLoading={isLoading}
          mode="compact"
        />
        <Flex flexDir="column" justifyContent="end">
          {!config.UI.views.tx.hiddenFields?.value && (
            <Skeleton isLoaded={!isLoading}>
              <Text as="span" variant="secondary">{getValueWithUnit(tx.value).dp(5).toFormat()}</Text>
              <Text ml={3} as="span" whiteSpace="pre">{currencyUnits.ether} </Text>
            </Skeleton>
          )}
          {!config.UI.views.tx.hiddenFields?.tx_fee && (
            <Skeleton isLoaded={!isLoading} display="flex" whiteSpace="pre">
              {tx.stability_fee ? (
                <TxFeeStability data={tx.stability_fee} accuracy={5} color="text_secondary" hideUsd />
              ) : (
                <Text as="span" variant="secondary">{tx.fee.value ? getValueWithUnit(tx.fee.value).dp(5).toFormat() : '-'}</Text>
              )}
              <Text ml={3} as="span">Fee </Text>
            </Skeleton>
          )}
        </Flex>
      </Flex>
    </Grid>
  );
};

export default React.memo(LatestTxsItem);
