import { Box, Heading, Flex } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import ChainIndicators from 'ui/home/indicators/ChainIndicators';
import LatestBlocks from 'ui/home/LatestBlocks';
import LatestZkEvmL2Batches from 'ui/home/LatestZkEvmL2Batches';
import Stats from 'ui/home/Stats';
import Transactions from 'ui/home/Transactions';
import ProfileMenuDesktop from 'ui/snippets/profileMenu/ProfileMenuDesktop';
import SearchBar from 'ui/snippets/searchBar/SearchBar';
import WalletMenuDesktop from 'ui/snippets/walletMenu/WalletMenuDesktop';

const rollupFeature = config.features.rollup;

const Home = () => {
  return (
    <Box as="main">
      <Box
        w="100%"
        background="lightGray"
        borderRadius="20px"
        padding={{ base: '24px', lg: '48px' }}
        minW={{ base: 'unset', lg: '900px' }}
        data-label="hero plate"
        display={{ base: 'none', lg: 'block' }}
      >
        <Flex mb={{ base: 6, lg: 8 }} justifyContent="space-between" alignItems="center">
          <Heading
            as="h1"
            size={{ base: 'md', lg: 'xl' }}
            lineHeight={{ base: '32px', lg: '50px' }}
            fontWeight={600}
            color="black"
          >
            Welcome to Hybrid Explorer
          </Heading>
          <Box display={{ base: 'none', lg: 'flex' }}>
            <Flex
              as="article"
              backgroundColor="baseGray"
              maxWidth="50px"
              maxHeight="50px"
              width="50px"
              height="50px"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
            >
            </Flex>
            {config.features.account.isEnabled && <ProfileMenuDesktop isHomePage />}
            {config.features.blockchainInteraction.isEnabled && <WalletMenuDesktop isHomePage />}
          </Box>
        </Flex>
        <SearchBar isHomepage />
      </Box>
      <Flex display={{ base: 'flex', lg: 'none' }} mt={10}/>
      <Stats/>
      <ChainIndicators />
      <Flex mt={8} direction={{ base: 'column', lg: 'row' }} columnGap={12} rowGap={8}>
        {rollupFeature.isEnabled && rollupFeature.type === 'zkEvm' ? <LatestZkEvmL2Batches /> : <LatestBlocks />}
        <Box flexGrow={1}>
          <Transactions />
        </Box>
      </Flex>
    </Box>
  );
};

export default Home;
